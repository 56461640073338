import { RemixBrowser } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { replayIntegration } from '@sentry/remix';
import { AxiosError, isAxiosError } from 'axios';
import { StrictMode, startTransition } from 'react';
import { hydrateRoot } from 'react-dom/client';

// NOTE: sentryのcontextにresponse内容を付与
const handleSentryBeforeSend = (
  event: Sentry.ErrorEvent,
  hint: Sentry.EventHint
) => {
  return addAxiosContextRecursive(event, hint?.originalException);
};

const addAxiosContextRecursive = (event: Sentry.ErrorEvent, error: unknown) => {
  if (isAxiosError(error)) {
    addAxiosContext(event, error);
  } else if (error instanceof Error && error.cause) {
    addAxiosContextRecursive(event, error.cause);
  }
  return event;
};

const addAxiosContext = (event: Sentry.ErrorEvent, error: AxiosError) => {
  if (error.response) {
    const contexts = { ...event.contexts };
    contexts.Axios = { Response: error.response };
    event.contexts = contexts;
  }
};

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [replayIntegration()],
  maxBreadcrumbs: 50,
  normalizeDepth: 5,
  sampleRate: 1,
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT, // local, stg, prod
  debug: process.env.NODE_ENV === 'development',
  beforeSend: handleSentryBeforeSend,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
